import type { App } from 'vue'
import { useShepherd } from 'vue-shepherd'
import './styles/index.scss'

export interface ShepherdStep {
  id: string
  title: string
  text: string
  attachTo?: {
    element: string | HTMLElement
    on: 'auto' | 'auto-start' | 'auto-end' | 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' | 'right-end' | 'left' | 'left-start' | 'left-end'
  }
  buttons?: Array<{
    text: string
    action?: () => void
    classes?: string
  }>
  arrow?: boolean
  beforeShowPromise?: () => Promise<void>
  modalOverlayOpeningPadding?: number
}

export interface TourConfig {
  useModalOverlay?: boolean
  defaultStepOptions?: {
    classes?: string
    scrollTo?: boolean
    cancelIcon?: {
      enabled: boolean
    }
    modalOverlayOpeningPadding?: number
    modalOverlayOpeningRadius?: number
    popperOptions?: {
      modifiers: Array<{
        name: string
        options: {
          offset: number[]
        }
      }>
    }
  }
  stepsContainer?: Element | null
}

export const createTour = (config?: TourConfig) => {
  return useShepherd({
    useModalOverlay: true,
    stepsContainer: document.querySelector('.layout-wrapper'),
    defaultStepOptions: {
      classes: 'shepherd-theme-custom',
      scrollTo: false,
      cancelIcon: {
        enabled: true
      },
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4,
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 12]
            }
          }
        ]
      },
      ...config?.defaultStepOptions
    },
    ...config
  })
}

export const installShepherdPlugin = (app: App) => {
  app.config.globalProperties.$createTour = createTour
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $createTour: typeof createTour
  }
} 
