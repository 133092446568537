<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import { onMounted } from "vue";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const router = useRouter();
const authStore = useAuthStore();

// Get the company details
// Run every 1 seconds and check if the current subscription key is different from the previous one
// Loop max 5 times
// If it is different, redirect to the appropriate page

const checkSubscriptionChange = async () => {
  let count = 0;

  const interval = setInterval(async () => {
    try {
      const isValid = await authStore.isValidSubscription();

      console.log(isValid);
      console.log(count);

      if (count >= 2) {
        router.push({
          name: "onboarding",
        });
        clearInterval(interval);
      } else if (!isValid) {
        console.error("Subscription is not valid");
        await authStore.regenerateToken();
        clearInterval(interval);
        router.push({
          name: "onboarding",
        });
      } else {
        count++;
      }
    } catch (error) {
      console.error("Error checking subscription:", error);
      clearInterval(interval);
    }
  }, 3000);
};

onMounted(async () => {
  await checkSubscriptionChange();
});
</script>

<template>
  <div class="subscription-check-container">
    <div class="subscription-check-content">
      <h1 class="subscription-title">Verifying Your Subscription</h1>
      <p class="subscription-subtitle">
        Please wait while we process your request
      </p>
      <div class="progress-container">
        <VProgressCircular
          size="64"
          indeterminate
          color="primary"
          class="progress-circular"
        />
      </div>
      <p class="subscription-message">You will be redirected automatically</p>
    </div>
  </div>
</template>

<style scoped>
.subscription-check-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #f8f9fa;
  min-block-size: 100vh;
}

.subscription-check-content {
  padding: 2rem;
  border-radius: 12px;
  background-color: white;
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 10%),
    0 2px 4px -2px rgb(0 0 0 / 10%);
  inline-size: 100%;
  max-inline-size: 600px;
  text-align: center;
}

.subscription-title {
  color: #1a1a1a;
  font-size: 1.875rem;
  font-weight: 600;
  margin-block-end: 0.75rem;
}

.subscription-subtitle {
  color: #666;
  font-size: 1.125rem;
  margin-block-end: 2rem;
}

.progress-container {
  margin-block: 2rem;
  margin-inline: 0;
}

.progress-circular {
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 7%));
}

.subscription-message {
  color: #666;
  font-size: 0.875rem;
  margin-block-start: 1rem;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .subscription-check-content {
    padding: 1.5rem;
  }

  .subscription-title {
    font-size: 1.5rem;
  }

  .subscription-subtitle {
    font-size: 1rem;
  }
}
</style>
