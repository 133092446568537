<script setup>
import atrLogo from "@images/logos/atr.png";
import AIPoweredImg from "@images/pages/ai_powered.png";
import DashboardImg from "@images/pages/dashboarding.png";
import OnboardingImg from "@images/pages/onboarding.png";

import { passwordValidator } from "@/@core/utils/validators";
import { useAuthStore } from "@/composables/store/useAuthStore";

const router = useRouter();
const route = useRoute();

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: true,
  },
});

// Get random name
const randomId = () => {
  return Math.random().toString(36).substr(2, 3) + "Agency";
};

const random = randomId();

// Get url params and set default values
const {
  company_name: routeCompanyName,
  user_email: routeUserEmail,
  company_type: routeCompanyType,
  company_website: routeCompanyWebsite,
  user_type: routeUserType,
  company_id: routeCompanyId,
} = route.query;

const env = import.meta.env.VITE_ENV;

const form = ref({
  name: env === "local" ? random : "",
  email: routeUserEmail || (env === "local" ? `${random}@attherate.ai` : ""),
  password: env === "local" ? "Soccer12" : "",
  companyName: routeCompanyName || (env === "local" ? random : ""),
  companyWebsite:
    routeCompanyWebsite || (env === "local" ? random + ".com" : ""),
  companyType: routeCompanyType || "agency",
  userType: routeUserType || null,
  subscriptionType: localStorage.getItem("subscriptionType") || "agency",
  privacyPolicies: false,
});

// Set initial value in localStorage if not present
onMounted(() => {
  if (!localStorage.getItem("subscriptionType")) {
    localStorage.setItem("subscriptionType", "agency");
  }
});

// Update watch effect to store subscription type
watch(
  () => form.value.subscriptionType,
  (newType) => {
    if (newType) {
      localStorage.setItem("subscriptionType", newType);
    }
  }
);

// Add computed property to check if user is invited
const isInvitedUser = computed(() => {
  return form.value.userType?.startsWith("invited_");
});

const { isSignupLoading, updateSignupUserData, signUp, signup } =
  useAuthStore();

const refVForm = ref(null);

const isPasswordVisible = ref(false);

// Add this to track if form was submitted
const isFormSubmitted = ref(false);

// Add these refs and data for carousel
const carouselSlides = ref([
  {
    image: OnboardingImg,
    title: "Instant Access to Your Client's Marketing Data",
    description:
      "Connect instantly to your clients' marketing platforms. One click unlocks Google, Meta, LinkedIn and more - no complex setup required.",
  },
  {
    image: DashboardImg,
    title: "Beautiful, Unified Marketing Dashboard",
    description:
      "Transform complex data into stunning visuals. View all your marketing metrics in one elegant, easy-to-understand dashboard that tells the complete story.",
  },
  {
    image: AIPoweredImg,
    title: "AI-Powered Marketing Intelligence",
    description:
      "Let AI be your marketing analyst. Get smart insights, performance predictions, and actionable recommendations while you focus on strategy.",
  },
]);

const currentSlide = ref(0);

onMounted(() => {
  const slideInterval = setInterval(() => {
    currentSlide.value = (currentSlide.value + 1) % carouselSlides.value.length;
  }, 2000);

  onBeforeUnmount(() => {
    clearInterval(slideInterval);
  });
});

const onSubmit = async () => {
  isFormSubmitted.value = true; // Set form as submitted

  const isValid = await refVForm.value.validate();
  if (!isValid.valid) return;

  updateSignupUserData({
    name: form.value.name,
    email: form.value.email,
    password: form.value.password,
    company_id: routeCompanyId || null,
    ...(!isInvitedUser.value && {
      company_name: form.value.companyName,
      company_website: form.value.companyWebsite,
      company_type: form.value.companyType,
    }),
  });

  await signUp();

  await nextTick(() => {
    router.replace(route.query.to ? String(route.query.to) : "/");
  });
};
</script>

<template>
  <VForm
    ref="refVForm"
    class="auth-form-wrapper"
    data-test-id="register-form"
    @submit.prevent="onSubmit"
  >
    <VRow
      no-gutters
      class="auth-wrapper bg-surface"
      data-test-id="register-page"
    >
      <!-- Left Column (Image) -->
      <VCol md="7" class="d-none d-md-flex auth-left-column">
        <div class="carousel-container">
          <div
            class="carousel-track"
            :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
          >
            <div
              v-for="(slide, index) in carouselSlides"
              :key="index"
              class="carousel-slide"
            >
              <div class="screenshot-wrapper">
                <VImg
                  :src="slide.image"
                  alt="App Screenshot"
                  class="login-auth-screenshot"
                  cover
                />
              </div>
              <h1 class="left-title">
                {{ slide.title }}
              </h1>
              <p class="left-description">
                {{ slide.description }}
              </p>
            </div>
          </div>

          <div class="carousel-indicators">
            <button
              v-for="(_, index) in carouselSlides"
              :key="index"
              class="carousel-indicator"
              :class="{ active: currentSlide === index }"
              @click="currentSlide = index"
            />
          </div>
        </div>
      </VCol>

      <!-- Right Column (Form) -->
      <VCol cols="12" md="5" class="auth-right-column">
        <VCard flat class="auth-card">
          <VCardText class="auth-content">
            <VImg
              data-test-id="register-logo"
              class="auth-logo"
              :src="atrLogo"
              alt="ATR Logo"
            />
            <p class="auth-subtitle" data-test-id="register-subtitle">
              Create account to continue.
            </p>

            <VRow class="auth-form">
              <!-- Company Type -->
              <VCol v-if="!isInvitedUser" cols="12" class="pb-0">
                <VBtnGroup
                  v-model="form.subscriptionType"
                  class="company-type-selector mb-2"
                  data-test-id="company-type-selector"
                >
                  <VBtn
                    :active="form.subscriptionType === 'agency'"
                    :class="{
                      'selected-type': form.subscriptionType === 'agency',
                    }"
                    data-test-id="agency-type-btn"
                    variant="outlined"
                    @click="form.subscriptionType = 'agency'"
                  >
                    <VIcon
                      start
                      icon="tabler-building-skyscraper"
                      class="me-2"
                    />
                    Agency
                  </VBtn>
                  <VBtn
                    :active="form.subscriptionType === 'client'"
                    :class="{
                      'selected-type': form.subscriptionType === 'client',
                    }"
                    data-test-id="client-type-btn"
                    variant="outlined"
                    @click="form.subscriptionType = 'client'"
                  >
                    <VIcon start icon="tabler-briefcase" class="me-2" />
                    Client
                  </VBtn>
                </VBtnGroup>

                <VAlert
                  variant="tonal"
                  color="primary"
                  class="company-type-alert mt-2"
                  density="comfortable"
                >
                  <template #prepend>
                    <VIcon
                      :icon="
                        form.subscriptionType === 'agency'
                          ? 'tabler-building-skyscraper'
                          : 'tabler-briefcase'
                      "
                      start
                      class="ms-1"
                    />
                  </template>
                  <strong class="d-block mb-1">
                    {{
                      form.subscriptionType === "agency"
                        ? "Marketing Agency"
                        : "Business"
                    }}
                  </strong>
                  <p class="text-body-2 mb-0">
                    {{
                      form.subscriptionType === "agency"
                        ? "Perfect for agencies managing multiple client accounts. Includes advanced features for multi-account management and client reporting."
                        : "Ideal for businesses managing their own accounts. Includes essential features for single company marketing analytics."
                    }}
                  </p>
                </VAlert>
              </VCol>
              <!-- Username -->
              <VCol cols="12" class="py-0">
                <AppTextField
                  v-model="form.name"
                  data-test-id="name-input"
                  :disabled="isSignupLoading"
                  :rules="[requiredValidator]"
                  label="Name"
                  placeholder="Johndoe"
                />
              </VCol>

              <!-- email -->
              <VCol v-if="form.userType !== 'invited'" cols="12" class="py-1">
                <AppTextField
                  v-model="form.email"
                  data-test-id="email-input"
                  :disabled="isSignupLoading || routeUserEmail"
                  :rules="[requiredValidator, emailValidator]"
                  label="Email"
                  type="email"
                  placeholder="johndoe@email.com"
                />
              </VCol>

              <!-- Company Name -->
              <VCol v-if="!isInvitedUser" cols="12" class="py-1">
                <AppTextField
                  v-model="form.companyName"
                  data-test-id="company-name-input"
                  :disabled="isSignupLoading || routeCompanyName"
                  :rules="[requiredValidator]"
                  label="Company Name"
                  placeholder="John Company Co."
                />
              </VCol>

              <!-- Company Website -->
              <VCol v-if="!isInvitedUser" cols="12" class="py-1">
                <AppTextField
                  v-model="form.companyWebsite"
                  data-test-id="company-website-input"
                  :disabled="isSignupLoading || routeCompanyWebsite"
                  :rules="[requiredValidator]"
                  label="Company Website"
                  placeholder="attherate.com"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12" class="py-1">
                <AppTextField
                  v-model="form.password"
                  data-test-id="password-input"
                  :disabled="isSignupLoading"
                  :rules="[requiredValidator, passwordValidator]"
                  label="Password"
                  placeholder="············"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="
                    isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'
                  "
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />
              </VCol>

              <VCol cols="12">
                <div class="privacy-policy">
                  <VCheckbox
                    id="privacy-policy"
                    v-model="form.privacyPolicies"
                    data-test-id="privacy-policy-checkbox"
                    :rules="[requiredValidator]"
                    inline
                  >
                    <template #label>
                      <VLabel for="privacy-policy" style="opacity: 1">
                        <span target="_blank" class="me-1">I agree to</span>
                        <a
                          data-test-id="privacy-policy-link"
                          href="https://www.attherate.ai/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="text-primary"
                          >privacy policy</a
                        >
                        <span class="mx-1">and</span>
                        <a
                          data-test-id="terms-of-use-link"
                          href="https://www.attherate.ai/terms-of-use"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="text-primary"
                          @click.stop
                          >terms of use</a
                        >
                      </VLabel>
                    </template>
                  </VCheckbox>
                </div>
                <VBtn
                  data-test-id="signup-submit-btn"
                  :loading="signup.isLoading"
                  block
                  type="submit"
                >
                  Sign up
                </VBtn>
              </VCol>

              <!-- create account -->
              <VCol cols="12" class="auth-footer">
                <span>Already have an account?</span>
                <RouterLink
                  data-test-id="signin-link"
                  class="text-primary ms-2"
                  :to="{ name: 'login' }"
                >
                  Sign in instead
                </RouterLink>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </VForm>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";

.auth-left-column {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgb(var(--v-theme-primary));
  color: white;
  min-block-size: 100vh;
}

.left-content {
  max-inline-size: min(600px, 90%);
  text-align: start;
}

.left-title {
  color: white;
  font-size: clamp(24px, 3vw, 32px);
  font-weight: 600;
  margin-block-end: 1rem;
  padding-inline: 1rem;
}

.left-description {
  color: rgba(255, 255, 255, 90%);
  font-size: clamp(14px, 1.5vw, 16px);
  line-height: 1.6;
  margin-block-end: 3rem;
  padding-inline: 1rem;
}

.auth-right-column {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgb(var(--v-theme-surface));
  block-size: 100vh;
  overflow-y: auto;
  padding-block: 2rem;
}

.auth-card {
  block-size: auto;
  inline-size: 100%;
  margin-block: auto;
  max-inline-size: min(500px, 90%);
}

.auth-content {
  padding: 1rem;

  @media (max-width: 600px) {
    padding: 0.75rem;
  }
}

.auth-logo {
  margin-block-end: 1rem;
  max-inline-size: 200px;

  @media (max-width: 600px) {
    margin-block-end: 0.75rem;
    max-inline-size: 150px;
  }
}

.auth-subtitle {
  color: var(--gray-600, #535862);
  font-size: 16px;
  margin-block-end: 1rem;

  @media (max-width: 600px) {
    font-size: 14px;
    margin-block-end: 0.75rem;
  }
}

.auth-form {
  gap: 0.5rem;
}

:deep(.v-col) {
  padding-block: 4px;
}

.privacy-policy {
  display: flex;
  align-items: center;
  margin-block: 0.75rem 1rem;
}

.auth-footer {
  margin-block-start: 1rem;
  text-align: center;
}

// Fix for smaller screens
@media (max-width: 960px) {
  .auth-left-column {
    display: none;
  }

  .auth-right-column {
    padding: 1rem;
  }
}

@media (max-width: 600px) {
  .auth-wrapper {
    padding: 0.5rem;
  }
}

.auth-form-wrapper {
  display: flex;
  overflow: hidden;
  block-size: 100vh;
}

.carousel-container {
  position: relative;
  overflow: hidden;
  inline-size: 100%;
  padding-block: 20px;
  padding-inline: 0;
}

.carousel-track {
  display: flex;
  inline-size: 100%;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.carousel-slide {
  flex: 0 0 100%;
  inline-size: 100%;
  padding-block: 30px;
  padding-inline: 20px;
  text-align: center;
}

.screenshot-wrapper {
  position: relative;
  overflow: visible;
  padding: 20px;
  margin: -20px;
  margin-inline: auto;
  max-inline-size: 900px;
  scale: 1.1;
}

.login-auth-screenshot {
  position: relative;
  z-index: 1;
  border-radius: 16px;
  animation: float 6s ease-in-out infinite;
  aspect-ratio: 16/9;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 20%);
  inline-size: 100%;
  margin-block-end: 2.5rem;
  margin-inline: auto;
  max-inline-size: 100%;
  object-fit: contain;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, box-shadow;

  &:hover {
    z-index: 2;
    box-shadow: 0 30px 50px rgba(0, 0, 0, 30%);
    transform: translateY(-10px) scale(1.03);
  }
}

.carousel-indicators {
  position: absolute;
  z-index: 2;
  display: flex;
  gap: 0.8rem;
  inset-block-end: 2rem;
  inset-inline-start: 50%;
  transform: translateX(-50%);
}

.carousel-indicator {
  padding: 0;
  border: 2px solid rgba(255, 255, 255, 50%);
  border-radius: 50%;
  background: transparent;
  block-size: 12px;
  cursor: pointer;
  inline-size: 12px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &.active {
    border-color: white;
    background: white;
    transform: scale(1.2);
  }

  &:hover {
    border-color: white;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.company-type-selector {
  inline-size: 100%;

  .v-btn {
    position: relative;
    flex: 1;
    border: 1px solid rgb(var(--v-theme-primary));
    cursor: pointer;
    padding-block: 12px;
    padding-inline: 16px;
    text-transform: none;
    transition: all 0.3s ease;

    &.selected-type {
      border-color: rgb(var(--v-theme-primary));
      background-color: rgb(var(--v-theme-primary)) !important;
      color: white !important;
    }

    &:not(.selected-type) {
      background-color: transparent !important;
      color: rgb(var(--v-theme-primary)) !important;
    }

    &:hover:not(.selected-type) {
      background-color: rgba(var(--v-theme-primary), 0.1) !important;
      transform: translateY(-1px);
    }
  }
}

:deep(.v-tooltip .v-overlay__content) {
  border-radius: 6px;
  background: rgba(var(--v-theme-surface-variant), 0.98);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 10%);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 1.5;
  max-inline-size: 200px;
  padding-block: 8px;
  padding-inline: 12px;
  text-align: start;
  white-space: pre-line;

  // Improved spacing for bullet points
  & > span {
    display: block;
    margin-block: 4px;
  }
}

.company-type-alert {
  font-size: 13px;
  line-height: 1.5;

  .v-alert-title {
    font-size: 14px;
    letter-spacing: 0.2px;
  }

  p {
    opacity: 0.9;
  }
}
</style>
