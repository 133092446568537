<template>
  <div class="flex flex-col items-center justify-center min-h-screen">
    <VProgressCircular color="primary" indeterminate :size="50" :width="3" />
    <span class="mt-4 text-gray-600 animate-pulse">
      Connecting to Google Search Console...
    </span>
  </div>
</template>

<script setup>
import { useErrorStore } from "@/composables/store/useErrorStore";
import { useSocialConnection } from "@/composables/store/useSocialConnection";
import { useRoute, useRouter } from "vue-router";

definePage({
  meta: {
    layout: "blank",
  },
});

const route = useRoute();
const router = useRouter();
const socialConnection = useSocialConnection();

const init = async () => {
  const { code, state } = route.query;
  console.log("code", code);
  saveSearchConsoleAuthToken();
};

const saveSearchConsoleAuthToken = async () => {
  try {
    const { code, state } = route.query;
    console.log("code", code);

    if (!code) {
      useErrorStore().setErrorMessage(
        "Failed to connect to Google Search Console.",
      );
      return;
    }

    const oAuthResponse = await socialConnection.finishSearchConsoleOAuth(
      code,
      null,
      null,
      "onboarding",
    );

    if (!oAuthResponse || oAuthResponse.error) {
      const errorMessage =
        oAuthResponse?.message || "Failed to connect to Google Search Console.";
      useErrorStore().setErrorMessage(errorMessage);
      router.push("/error"); // Redirect to an error page or handle error appropriately
      return;
    }

    // Redirect to a success page or dashboard
    router.push({
      name: "dashboard",
      query: {
        service_token: oAuthResponse.service_token_pk,
      },
    });
  } catch (error) {
    useErrorStore().setErrorMessage("An error occurred while connecting.");
    router.push("/error"); // Redirect to an error page or handle error appropriately
  }
};
init();

// Call the function to handle the OAuth token
// saveSearchConsoleAuthToken();
</script>

<style scoped>
.flex {
  display: flex;
}

.min-h-screen {
  min-block-size: 100vh;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.mt-4 {
  margin-block-start: 1rem;
}

.text-gray-600 {
  color: #718096;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}
</style>
