<script setup>
// Core imports
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

// Components
import NavBar from "@/@core/components/NavBar.vue";
import CancelSubscriptionDrawer from "@/components/drawer/CancelSubscriptionDrawer.vue";
import CreateCompanyDetailsDrawer from "@/components/drawer/CreateCompanyDetailsDrawer.vue";
import CreateUserDetailsDrawer from "@/components/drawer/CreateUserDetailsDrawer.vue";
import ProfilePicture from "./ProfilePicture.vue";

// Utils and stores
import { dateLeft } from "@/@core/utils/helpers";
import { useAuthStore } from "@/composables/store/useAuthStore";
import { useErrorStore } from "@/composables/store/useErrorStore";
import { useReportStore } from "@/composables/store/useReportStore";
import { useSuccessStore } from "@/composables/store/useSuccessStore";

// Page configuration
definePage({
  meta: { layout: "blank" },
});

// Store initialization
const router = useRouter();
const authStore = useAuthStore();
const reportStore = useReportStore();
const successStore = useSuccessStore();
const errorStore = useErrorStore();
const { company } = storeToRefs(authStore);

// State
const signedProfilePictureUrl = ref("");
const cancelDialogState = ref(false);

// Computed
const cancelFormState = computed({
  get: () => ({
    ...authStore.contactForm,
    name: authStore.user?.name || "",
    email: authStore.user?.email || "",
    description:
      "I would like to request cancellation of my subscription.\n\nReason: ",
    subject: "Subscription Cancellation Request",
  }),
  set: (value) => {
    authStore.contactForm = value;
  },
});

// Event handlers
const onCompanyEditClick = () => {
  authStore.isCreateCompanyDrawerOpen = true;
  authStore.createCompanyForm = {
    name: authStore.company?.name,
    email: authStore.company?.email,
    website: authStore.company?.website,
  };
};

const onUserEditClick = () => {
  authStore.isCreateUserDrawerOpen = true;
  authStore.createUserForm = {
    name: authStore.user?.name,
  };
};

const onInvoiceClick = (invoice) => window.open(invoice.pdf_url, "_blank");

const onSubscriptionViewClick = () => {
  router.push({ name: "onboarding", query: { activeTab: 0 } });
};

const onSubscriptionCancelClick = () => {
  cancelDialogState.value = true;
};

const onCancelSubmit = async () => {
  try {
    await authStore.contactUs();
    cancelDialogState.value = false;
    successStore.setSuccessMessage(
      "Your cancellation request has been submitted successfully"
    );
  } catch (error) {
    console.error("Error submitting cancellation request:", error);
    errorStore.setErrorMessage(
      "Failed to submit cancellation request. Please try again."
    );
  }
};

// Subscription tier details
const getSubscriptionTierDetails = computed(() => {
  const currentTierName =
    authStore.companySubscription?.subscription_tier?.tier_name;

  const allTiers = authStore.subscriptionTiers || [];

  // Get all subscribed tiers
  const subscribedTiers = allTiers.filter(
    (tier) => tier.subscription_id !== null
  );

  // If no subscribed tiers found, try to find by tier name
  if (subscribedTiers.length === 0 && currentTierName) {
    const tierByName = allTiers.find(
      (tier) => tier.tier_name === currentTierName
    );

    return tierByName
      ? [
          {
            tier_name: tierByName.tier_name,
            price: tierByName.price,
            subscription_type: tierByName.subscription_type,
            color: "primary",
          },
        ]
      : [];
  }

  // Return all subscribed tiers sorted by price
  return subscribedTiers
    .sort((a, b) => b.price - a.price)
    .map((tier) => ({
      tier_name: tier.tier_name,
      price: tier.price,
      subscription_type: tier.subscription_type,
      color: "primary",
    }));
});

// Initialization
const init = async () => {
  try {
    await Promise.all([
      authStore.getCompanyDetails(),
      authStore.getSubscriptionInvoices(),
      authStore.getTiers(),
    ]);

    const companyPk = company.value?.company_pk;
    if (!companyPk) {
      console.error("Company PK is undefined.");

      return;
    }

    const { profile_picture_url } =
      await authStore.getCompanyProfilePicture(companyPk);

    signedProfilePictureUrl.value = profile_picture_url
      ? await reportStore.generatePublicLinkFromS3(profile_picture_url)
      : "";
  } catch (error) {
    console.error("Error in initialization:", error);
    signedProfilePictureUrl.value = "";
  }
};

init();
</script>

<template>
  <!-- Navigation and Drawers -->
  <NavBar data-test-id="settings-nav-bar" />
  <CreateCompanyDetailsDrawer data-test-id="company-details-drawer" />
  <CreateUserDetailsDrawer data-test-id="user-details-drawer" />
  <CancelSubscriptionDrawer data-test-id="cancel-subscription-drawer" />

  <!-- Main Container -->
  <div class="settings-container pa-6" data-test-id="settings-container">
    <!-- Back Button - Updated to use router.back() -->
    <VBtn data-test-id="back-btn" class="mb-6" variant="tonal" prepend-icon="tabler-arrow-left" @click="router.back()">
      Back
    </VBtn>

    <!-- Profile Picture Section -->
    <div class="profile-section mb-6" data-test-id="profile-section">
      <ProfilePicture data-test-id="profile-picture" />
    </div>

    <!-- Main Content Card -->
    <VCard class="settings-card mb-6" elevation="1" data-test-id="settings-card">
      <!-- Company Information Section -->
      <div class="section-header pa-4" data-test-id="company-info-header">
        <VCardTitle class="text-h6">Company Information</VCardTitle>
        <VSpacer />
        <VBtn data-test-id="edit-company-btn" prepend-icon="tabler-edit" variant="tonal" @click="onCompanyEditClick">
          Edit
        </VBtn>
      </div>

      <VDivider />

      <div class="pa-4">
        <VList class="settings-list">
          <VListItem>
            <template #prepend>
              <VIcon data-test-id="company-type-icon" icon="tabler-building" color="primary" size="20" class="me-3" />
            </template>
            <VListItemTitle data-test-id="company-type-title">
              <span class="font-weight-medium me-2">Company Type:</span>
              <span class="text-medium-emphasis">{{
                toSentenceCase(authStore.company?.company_type)
              }}</span>
            </VListItemTitle>
          </VListItem>
          <VListItem>
            <template #prepend>
              <VIcon data-test-id="company-name-icon" icon="tabler-user" size="20" class="me-2" />
            </template>

            <VListItemTitle data-test-id="company-name-title">
              <span class="font-weight-medium me-1">Company Name:</span>
              <span>{{ authStore.company?.name }}</span>
            </VListItemTitle>
          </VListItem>
          <VListItem>
            <template #prepend>
              <VIcon data-test-id="company-email-icon" icon="tabler-mail" size="20" class="me-2" />
            </template>
            <VListItemTitle data-test-id="company-email-title">
              <span class="font-weight-medium me-1">Email:</span>
              <span>{{ authStore.company?.email || "-" }}</span>
            </VListItemTitle>
          </VListItem>
          <VListItem>
            <template #prepend>
              <VIcon data-test-id="company-website-icon" icon="tabler-globe" size="20" class="me-2" />
            </template>
            <VListItemTitle data-test-id="company-website-title">
              <span class="font-weight-medium me-1">Website:</span>
              <span>{{ authStore.company?.website }}</span>
            </VListItemTitle>
          </VListItem>
        </VList>
      </div>

      <!-- User Information Section -->
      <VDivider />

      <div class="section-header pa-4" data-test-id="user-info-header">
        <VCardTitle class="text-h6">User Information</VCardTitle>
        <VSpacer />
        <VBtn data-test-id="edit-user-btn" prepend-icon="tabler-edit" variant="tonal" @click="onUserEditClick">
          Edit
        </VBtn>
      </div>

      <VDivider />

      <div class="pa-4">
        <VList class="settings-list">
          <VListItem>
            <template #prepend>
              <VIcon data-test-id="user-email-icon" icon="tabler-world" size="20" class="me-2" />
            </template>
            <VListItemTitle data-test-id="user-email-title">
              <span class="font-weight-medium me-1">User Email:</span>
              <span>{{ authStore.user?.email }}</span>
            </VListItemTitle>
          </VListItem>
          <VListItem>
            <template #prepend>
              <VIcon data-test-id="user-name-icon" icon="tabler-user" size="20" class="me-2" />
            </template>

            <VListItemTitle data-test-id="user-name-title">
              <span class="font-weight-medium me-1">User Name:</span>
              <span>{{ authStore.user?.name }}</span>
            </VListItemTitle>
          </VListItem>
        </VList>
      </div>

      <!-- Subscription Section -->
      <VDivider />

      <div class="section-header pa-4" data-test-id="subscription-header">
        <VCardTitle class="text-h6">Subscription Information</VCardTitle>
        <VSpacer />
        <div class="d-flex gap-2">
          <VBtn data-test-id="view-subscription-btn" prepend-icon="tabler-eye" variant="tonal"
            @click="onSubscriptionViewClick">
            View
          </VBtn>

          <VBtn data-test-id="invoices-btn" prepend-icon="tabler-file-invoice" variant="tonal"
            :disabled="!authStore.companySubscriptionTiersByKeys?.length">
            Invoices
            <VMenu data-test-id="invoices-menu" open-on-hover activator="parent" close-on-content-click>
              <VList v-if="authStore?.invoices?.length > 0" density="compact">
                <VListItem v-for="item in authStore.invoices" :key="item.id" class="invoice-item"
                  @click="onInvoiceClick(item)">
                  <template #prepend>
                    <VIcon size="18" icon="tabler-file" class="me-2" />
                  </template>
                  {{ new Date(item.created_date * 1000).toDateString() }}
                </VListItem>
              </VList>
              <VList v-else density="compact">
                <VListItem>
                  <VListItemTitle class="text-medium-emphasis">
                    No invoices found
                  </VListItemTitle>
                </VListItem>
              </VList>
            </VMenu>
          </VBtn>

          <VBtn data-test-id="cancel-subscription-btn" color="error" variant="tonal" prepend-icon="tabler-x"
            :disabled="!authStore.companySubscriptionTiersByKeys?.length" @click="onSubscriptionCancelClick">
            Cancel Subscription
          </VBtn>
        </div>
      </div>

      <VDivider />
      <div class="pa-4">
        <VList class="settings-list">
          <VListItem>
            <template #prepend>
              <VIcon icon="tabler-package" size="20" class="me-2" color="primary" />
            </template>
            <VListItemTitle>
              <div class="d-flex flex-column">
                <span class="font-weight-medium mb-2">Subscription Package:</span>
                <div class="d-flex flex-wrap gap-2">
                  <template v-if="authStore.companySubscriptionTiers?.length">
                    <div v-for="tier in authStore.companySubscriptionTiers"
                      :key="tier.subscription_tier.subscription_tier_pk" class="subscription-tier d-flex align-center">
                      <VChip data-test-id="subscription-tier-chip" size="small" color="primary"
                        class="subscription-chip" elevation="1" label>
                        <VIcon data-test-id="subscription-tier-icon" start size="16" icon="tabler-crown" class="me-1" />
                        {{ tier.subscription_tier.tier_name }}
                      </VChip>
                      <span v-if="tier.subscription_tier.price" data-test-id="subscription-price"
                        class="price-tag ms-2">
                        <span class="currency">$</span>
                        {{ tier.subscription_tier.price }}
                        <span class="period text-caption">/ month</span>
                      </span>
                    </div>
                  </template>
                  <VChip v-else size="small" color="primary" class="subscription-chip" elevation="1" label>
                    <VIcon start size="16" icon="tabler-alert-circle" class="me-1" />
                    No active subscription
                  </VChip>
                </div>
              </div>
            </VListItemTitle>
          </VListItem>
          <VListItem>
            <template #prepend>
              <VIcon icon="tabler-clock" size="20" class="me-2" />
            </template>

            <VListItemTitle>
              <span class="font-weight-medium me-1">Start Date:</span>
              <span>{{ authStore.companySubscription?.start_date }}</span>
            </VListItemTitle>
          </VListItem>
          <VListItem>
            <template #prepend>
              <VIcon icon="tabler-clock" size="20" class="me-2" />
            </template>

            <VListItemTitle>
              <span class="font-weight-medium me-1">End Date:</span>
              <span>{{ authStore.companySubscription?.end_date }}</span>
            </VListItemTitle>
          </VListItem>
          <VListItem>
            <template #prepend>
              <VIcon icon="tabler-clock" size="20" class="me-2" />
            </template>

            <VListItemTitle>
              <span class="font-weight-medium me-1">Days Left:</span>
              <span>{{
                dateLeft(new Date(authStore.companySubscription?.end_date))
              }}</span>
            </VListItemTitle>
          </VListItem>
        </VList>
      </div>
    </VCard>
  </div>

  <!-- Add this dialog at the end of your template -->
  <VDialog v-model="cancelDialogState" max-width="600px" data-test-id="cancel-subscription-dialog">
    <VCard class="pa-6">
      <div class="d-flex flex-column gap-4">
        <!-- Header -->
        <div class="d-flex align-center gap-4">
          <VAvatar color="error" size="48">
            <VIcon size="24" icon="tabler-alert-circle" />
          </VAvatar>
          <div>
            <h3 class="text-h6 font-weight-medium mb-1">
              Request Subscription Cancellation
            </h3>
            <p class="text-body-2 text-medium-emphasis">
              Please let us know why you'd like to cancel. We value your
              feedback.
            </p>
          </div>
        </div>

        <!-- Form -->
        <VForm data-test-id="cancel-subscription-form" @submit.prevent="onCancelSubmit">
          <div class="d-flex flex-column gap-4">
            <AppTextField v-model="cancelFormState.name" data-test-id="cancel-form-name" :rules="[requiredValidator]"
              prepend-inner-icon="tabler-user" label="Name" placeholder="Your name" />

            <AppTextField v-model="cancelFormState.email" data-test-id="cancel-form-email"
              :rules="[requiredValidator, emailValidator]" prepend-inner-icon="tabler-mail" label="Email address"
              type="email" placeholder="your@email.com" />

            <AppTextarea v-model="cancelFormState.description" data-test-id="cancel-form-description"
              :rules="[requiredValidator]" label="Cancellation Details"
              placeholder="Please provide additional details about your cancellation request" rows="4" />

            <!-- Actions -->
            <div class="d-flex gap-3 mt-2">
              <VBtn data-test-id="submit-cancel-request-btn" color="error" variant="elevated"
                :loading="authStore.isContactUsLoading" @click="onCancelSubmit">
                Submit Request
              </VBtn>
              <VBtn data-test-id="close-cancel-dialog-btn" color="secondary" variant="tonal"
                @click="cancelDialogState = false">
                Cancel
              </VBtn>
            </div>
          </div>
        </VForm>
      </div>
    </VCard>
  </VDialog>
</template>

<style lang="scss" scoped>
.settings-container {
  margin-block: 0;
  margin-inline: auto;
  max-inline-size: 1200px;
}

.settings-card {
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 8%) !important;
  }
}

.section-header {
  display: flex;
  align-items: center;
  min-block-size: 64px;
}

.settings-list {
  --v-card-list-gap: 16px;

  .v-list-item {
    border-radius: 8px;
    min-block-size: 48px;
    padding-block: 8px;
    padding-inline: 16px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: rgb(var(--v-theme-surface-variant), 0.08);
    }
  }
}

.invoice-item {
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgb(var(--v-theme-surface-variant));
  }
}

.subscription-tier {
  border-radius: 8px;
  background: rgb(var(--v-theme-surface-variant), 0.08);
  padding-block: 8px;
  padding-inline: 12px;
  transition: all 0.2s ease;

  &:hover {
    background: rgb(var(--v-theme-surface-variant), 0.12);
    transform: translateY(-1px);
  }
}

.subscription-chip {
  font-weight: 600;
  letter-spacing: 0.5px;
}

.price-tag {
  color: rgb(var(--v-theme-on-surface));
  font-weight: 500;

  .currency {
    color: rgb(var(--v-theme-primary));
    font-weight: 600;
  }

  .period {
    color: rgb(var(--v-theme-on-surface), 0.7);
  }
}
</style>
