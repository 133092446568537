import { useAuthStore } from "@/composables/store/useAuthStore";
import { useCategoryStore } from "@/composables/store/useCategoryStore";
import { LOCALSTORAGE } from "@/utils/constants";

export const setupGuards = (router) => {
  let isInitializing = false;

  router.beforeEach(async (to, from, next) => {
    const { regenerateTokenByApiKey, regenerateTokenByToken, currentAgency } =
      useAuthStore();

    try {
      // 2. API Key handling
      if (to.path.includes("reset-password")) {
        return next();
      }

      if (to.query.api_key?.trim() || to.query.token?.trim()) {
        isInitializing = true;

        try {
          if (to.query.api_key?.trim()) {
            await regenerateTokenByApiKey(to.query.api_key.trim());
          } else if (to.query.token?.trim()) {
            await regenerateTokenByToken(to.query.token.trim());
          }

          isInitializing = false;

          // Remove query parameters and redirect to clean URL
          // const { query, ...routeWithoutQuery } = to;

          return next();
        } catch (err) {
          console.error("❌ Authentication failed:", err);
          isInitializing = false;

          return next("/login");
        }
      }

      const isLoggedIn = checkAuthStatus();

      if (to.meta.unauthenticatedOnly) {
        return isLoggedIn ? next("/") : next();
      }

      // 6. Initialize data for authenticated users
      if (!isInitializing && isLoggedIn) {
        isInitializing = true;

        try {
          const authStore = useAuthStore();
          const { initAuthData, setAuthToken } = authStore;
          const { initCategories } = useCategoryStore();

          setAuthToken(localStorage.getItem(LOCALSTORAGE.HASURA_ACCESS_TOKEN));
          await Promise.all([initAuthData(), initCategories()]);
        } catch (err) {
          console.error("❌ Initialization failed:", err);
          isInitializing = false;

          return next("/login");
        }
        isInitializing = false;
      }

      // 4. Handle public and unauthenticated-only routes early
      if (to.meta.public) {
        return next();
      }

      // 1. Login page handling
      if (to.name === "login") {
        const isLoggedIn = checkAuthStatus();

        return isLoggedIn && !from.name ? next("/") : next();
      }

      // 5. Handle protected routes
      if (!isLoggedIn) {
        return next({
          name: "login",
          query: { to: to.fullPath !== "/" ? to.fullPath : undefined },
        });
      }

      // 7. Client Service Restrictions
      const authStore = useAuthStore();
      if (authStore.user?.user_type === "client_service") {
        const allowedPaths = ["/dashboard", "/connect-"];

        const isAllowedPath = allowedPaths.some(
          (path) =>
            to.path === path ||
            (path === "/connect-" && to.path.startsWith("/connect-"))
        );

        if (!isAllowedPath) {
          if (localStorage.getItem("service_module") === "dashboard") {
            return next("/dashboard");
          } else if (
            localStorage.getItem("service_module") === "connect_client"
          ) {
            return next(`pages/connect-${currentAgency?.name}`);
          }

          return next("/login");
        }
      }

      return next();
    } catch (error) {
      console.error("❌ Navigation guard error:", error);
      isInitializing = false;

      return next("/login");
    }
  });
};

function checkAuthStatus() {
  return !!localStorage.getItem(LOCALSTORAGE.HASURA_ACCESS_TOKEN);
}
